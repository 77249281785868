

<template>
  <div>
    <GeneralBalance></GeneralBalance>
  </div>
</template>
<script>
import GeneralBalance from "@/views/commons/components/reports/tabs/general-balance/GeneralBalance.vue";

export default {
  components: {
    GeneralBalance,
  },
};
</script>